import React, {useRef} from "react";

import { useLanguage } from '../../contexts/LanguageContext';

import { useInView } from "framer-motion";
import {Link as ScrollLink} from "react-scroll";

import {FaArrowDownLong} from "react-icons/fa6";
import arrow from "../../assets/images/arrow.png";
import arrowWeb from "../../assets/images/arrow.webp";

function Skills() {

    const ref = useRef(null);
    const isInView = useInView(ref);

    ScrollLink.defaultProps = {
        smooth: true,
        duration: 500, 
        offset: -70, 
    };

    const { language } = useLanguage();

    const translations = {
        en: {
            title: "Skills",
            catchphrase: "I always try to improve myself",
            otherTech: `Other technos`,
            funskill: "COFFEE DRINKING",
            softskills: ["problem solving", "team work", "creativity", "organization", "adaptability", "critical thinking"],
            scrollbtn: "LEARN MORE"
        },
        fr: {
            title: "Compétences",
            catchphrase: "J'essaye toujours de m'améliorer",
            otherTech: "Autres technos",
            funskill: "BOIRE DES LITRES DE CAFÉ",
            softskills: ["problem solving", "travail d'équipe", "créativité", "organisation", "adaptabilité", "esprit critique"],
            scrollbtn: "EN SAVOIR PLUS"
        },
    };

    const translation = translations[language];

    return (
        <section className="pSkills">
            <div className="pSkills--title">
                <h2>{translation.title}</h2>
                <div className="pMain--section--pic--arrow">
                    <span>{translation.catchphrase}</span>
                    <picture>
                        <source srcSet={arrowWeb} type="image/webp" height="100" width="100"/>
                        <source srcSet={arrow} type="image/png" height="100" width="100"/>
                        <img src={arrow} alt="flèche" height="100" width="100"/>
                    </picture>
                </div>
            </div>
            <div className="pSkills--container">
                <div className="pSkills--container--tech">
                        <div id="graph"  className={`pSkills--container--tech--chart ${isInView && "seen"}`} ref={ref}>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "90%"}}><span>{translation.funskill}</span></div><span className="pourcentage">95%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "85%"}}><span>HTML/CSS</span></div><span className="pourcentage">90%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "80%"}}><span>JAVASCRIPT</span></div><span className="pourcentage">85%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "75%"}}><span>REACT / NEXT</span></div><span className="pourcentage">80%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "70%"}}><span>WORDPRESS</span></div><span className="pourcentage">75%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "60%"}}><span>VUE.JS</span></div><span className="pourcentage">60%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "45%"}}><span>TYPESCRIPT</span></div><span className="pourcentage">40%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "45%"}}><span>UX DESIGN</span></div><span className="pourcentage">40%</span></div>
                            <div className="pSkills--container--tech--chart--skill"><div style={{height: "25%"}}><span>ANGULAR</span></div><span className="pourcentage">20%</span></div>
                        </div>
                    <h3>{translation.otherTech}</h3>
                    <div className="pSkills--container--tech--others">
                        <div>Sass</div>
                        <div>Git</div>
                        <div>Figma</div>
                        <div>Tailwind</div>
                        <div>Jest</div>
                    </div>
                </div>
                <div className="pSkills--container--soft">
                    <h3>Soft skills</h3>
                    <div className="pSkills--container--soft--clouds">
                        <div>
                            <div className="cloud">{translation.softskills[0]}</div>
                            <div className="cloud">{translation.softskills[1]}</div>
                            <div className="cloud">{translation.softskills[2]}</div>
                        </div>
                        <div>
                            <div className="cloud">{translation.softskills[3]}</div>
                            <div className="cloud">{translation.softskills[4]}</div>
                            <div className="cloud">{translation.softskills[5]}</div>
                        </div>
                    </div>
                    <div className="pMain--section--text--btn">
                        <span>{translation.scrollbtn}</span>
                        <ScrollLink to="pVision" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                            <button aria-label="Sroll to next section"><FaArrowDownLong/></button>
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;